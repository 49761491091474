export const bikesBrands = {
  id: 'brand',
  label: 'Brand',
  isOpen: false,
  isFilterable: true,
  type: 'DropDownFilter',
  group: 'primary',
  sortingOrder: 6,
  queryParamNames: ['pub_brand'],
  translationGroup: 'brand',
  skipItemPageTranslations: true,
  config: {
    options: [
        {
            "key": "no_brand",
            "value": "no_brand",
            "label": "No brand"
        },
        {
            "key": "other",
            "value": "other",
            "label": "Other"
        },
        {
            "key": "scott",
            "value": "scott",
            "label": "Scott"
        },
        {
            "key": "giant",
            "value": "giant",
            "label": "Giant"
        },
        {
            "key": "wilier",
            "value": "wilier",
            "label": "Wilier"
        },
        {
            "key": "cannondale",
            "value": "cannondale",
            "label": "Cannondale"
        },
        {
            "key": "trek",
            "value": "trek",
            "label": "Trek"
        },
        {
            "key": "specialized",
            "value": "specialized",
            "label": "Specialized"
        },
        {
            "key": "cube",
            "value": "cube",
            "label": "Cube"
        },
        {
            "key": "canyon",
            "value": "canyon",
            "label": "Canyon"
        },
        {
            "key": "marin",
            "value": "marin",
            "label": "Marin"
        },
        {
            "key": "schwinn",
            "value": "schwinn",
            "label": "Schwinn"
        },
        {
            "key": "fuji",
            "value": "fuji",
            "label": "Fuji"
        },
        {
            "key": "gt",
            "value": "gt",
            "label": "GT"
        },
        {
            "key": "niner",
            "value": "niner",
            "label": "Niner"
        },
        {
            "key": "whyte",
            "value": "whyte",
            "label": "Whyte"
        },
        {
            "key": "mongoose",
            "value": "mongoose",
            "label": "Mongoose"
        },
        {
            "key": "diamonback",
            "value": "diamonback",
            "label": "Diamonback"
        },
        {
            "key": "gary_fisher",
            "value": "gary_fisher",
            "label": "Gary Fisher"
        },
        {
            "key": "felt",
            "value": "felt",
            "label": "Felt"
        },
        {
            "key": "redline",
            "value": "redline",
            "label": "Redline"
        },
        {
            "key": "haro",
            "value": "haro",
            "label": "Haro"
        },
        {
            "key": "raleigh",
            "value": "raleigh",
            "label": "Raleigh"
        },
        {
            "key": "bianchi",
            "value": "bianchi",
            "label": "Bianchi"
        },
        {
            "key": "kink_industries",
            "value": "kink_industries",
            "label": "Kink Industries"
        },
        {
            "key": "santa_cruz",
            "value": "santa_cruz",
            "label": "Santa Cruz"
        },
        {
            "key": "cervelo",
            "value": "cervelo",
            "label": "Cervelo"
        },
        {
            "key": "colnago",
            "value": "colnago",
            "label": "Colnago"
        },
        {
            "key": "motobecane",
            "value": "motobecane",
            "label": "Motobecane"
        },
        {
            "key": "novara",
            "value": "novara",
            "label": "Novara"
        },
        {
            "key": "bmc",
            "value": "bmc",
            "label": "BMC"
        },
        {
            "key": "devinci",
            "value": "devinci",
            "label": "Devinci"
        },
        {
            "key": "khs",
            "value": "khs",
            "label": "KHS"
        },
        {
            "key": "pivot",
            "value": "pivot",
            "label": "Pivot"
        },
        {
            "key": "roadmaster",
            "value": "roadmaster",
            "label": "Roadmaster"
        },
        {
            "key": "campagnolo",
            "value": "campagnolo",
            "label": "Campagnolo"
        },
        {
            "key": "jamis",
            "value": "jamis",
            "label": "Jamis"
        },
        {
            "key": "orbea",
            "value": "orbea",
            "label": "Orbea"
        },
        {
            "key": "ridley",
            "value": "ridley",
            "label": "Ridley"
        },
        {
            "key": "blackburn",
            "value": "blackburn",
            "label": "Blackburn"
        },
        {
            "key": "dk",
            "value": "dk",
            "label": "DK"
        },
        {
            "key": "eddy_merckx",
            "value": "eddy_merckx",
            "label": "Eddy Merckx"
        },
        {
            "key": "evo",
            "value": "evo",
            "label": "EVO"
        },
        {
            "key": "chrome",
            "value": "chrome",
            "label": "Chrome"
        },
        {
            "key": "ibis",
            "value": "ibis",
            "label": "Ibis"
        },
        {
            "key": "kona",
            "value": "kona",
            "label": "Kona"
        },
        {
            "key": "linskey_performace",
            "value": "linskey_performace",
            "label": "Linskey Performace"
        },
        {
            "key": "mtb",
            "value": "mtb",
            "label": "MTB"
        },
        {
            "key": "norco",
            "value": "norco",
            "label": "Norco"
        },
        {
            "key": "parlee",
            "value": "parlee",
            "label": "Parlee"
        },
        {
            "key": "pearl_izumi",
            "value": "pearl_izumi",
            "label": "Pearl Izumi"
        },
        {
            "key": "quintana_roo",
            "value": "quintana_roo",
            "label": "Quintana Roo"
        },
        {
            "key": "rocky_mountain",
            "value": "rocky_mountain",
            "label": "Rocky Mountain"
        },
        {
            "key": "salsa",
            "value": "salsa",
            "label": "Salsa"
        },
        {
            "key": "seven",
            "value": "seven",
            "label": "Seven"
        },
        {
            "key": "shimano",
            "value": "shimano",
            "label": "Shimano"
        },
        {
            "key": "triple_eight",
            "value": "triple_eight",
            "label": "Triple Eight"
        },
        {
            "key": "ktm",
            "value": "ktm",
            "label": "KTM"
        },
        {
            "key": "yeti",
            "value": "yeti",
            "label": "Yeti"
        },
        {
            "key": "focus",
            "value": "focus",
            "label": "Focus"
        },
        {
            "key": "commencal",
            "value": "commencal",
            "label": "Commencal"
        },
        {
            "key": "academy",
            "value": "academy",
            "label": "Academy"
        },
        {
            "key": "a2b",
            "value": "a2b",
            "label": "A2B"
        },
        {
            "key": "8bar",
            "value": "8bar",
            "label": "8bar"
        },
        {
            "key": "airborne",
            "value": "airborne",
            "label": "Airborne"
        },
        {
            "key": "airdrop",
            "value": "airdrop",
            "label": "Airdrop"
        },
        {
            "key": "airstreeem",
            "value": "airstreeem",
            "label": "Airstreeem"
        },
        {
            "key": "alchemy",
            "value": "alchemy",
            "label": "Alchemy"
        },
        {
            "key": "alias",
            "value": "alias",
            "label": "Alias"
        },
        {
            "key": "all_city",
            "value": "all_city",
            "label": "All-City"
        },
        {
            "key": "alutech",
            "value": "alutech",
            "label": "Alutech"
        },
        {
            "key": "amber",
            "value": "amber",
            "label": "Amber"
        },
        {
            "key": "amity",
            "value": "amity",
            "label": "Amity"
        },
        {
            "key": "anesi",
            "value": "anesi",
            "label": "Anesi"
        },
        {
            "key": "apollo",
            "value": "apollo",
            "label": "Apollo"
        },
        {
            "key": "arc8",
            "value": "arc8",
            "label": "Arc8"
        },
        {
            "key": "argon_18",
            "value": "argon_18",
            "label": "Argon 18"
        },
        {
            "key": "atalanta",
            "value": "atalanta",
            "label": "Atalanta"
        },
        {
            "key": "atomz",
            "value": "atomz",
            "label": "Atomz"
        },
        {
            "key": "avanti",
            "value": "avanti",
            "label": "Avanti"
        },
        {
            "key": "aventon",
            "value": "aventon",
            "label": "Aventon"
        },
        {
            "key": "ax_lightness",
            "value": "ax_lightness",
            "label": "AX Lightness"
        },
        {
            "key": "bakfiets",
            "value": "bakfiets",
            "label": "bakfiets"
        },
        {
            "key": "banshee",
            "value": "banshee",
            "label": "Banshee"
        },
        {
            "key": "basso",
            "value": "basso",
            "label": "Basso"
        },
        {
            "key": "batavus",
            "value": "batavus",
            "label": "Batavus"
        },
        {
            "key": "batch_bicycles",
            "value": "batch_bicycles",
            "label": "Batch Bicycles"
        },
        {
            "key": "bergamont",
            "value": "bergamont",
            "label": "Bergamont"
        },
        {
            "key": "berria",
            "value": "berria",
            "label": "Berria"
        },
        {
            "key": "black_eye",
            "value": "black_eye",
            "label": "Black Eye"
        },
        {
            "key": "blank",
            "value": "blank",
            "label": "Blank"
        },
        {
            "key": "bombtrack",
            "value": "bombtrack",
            "label": "Bombtrack"
        },
        {
            "key": "bottecchia",
            "value": "bottecchia",
            "label": "Bottecchia"
        },
        {
            "key": "cadence",
            "value": "cadence",
            "label": "Cadence"
        },
        {
            "key": "calibre",
            "value": "calibre",
            "label": "Calibre"
        },
        {
            "key": "califeira",
            "value": "califeira",
            "label": "Califeira"
        },
        {
            "key": "canfield",
            "value": "canfield",
            "label": "Canfield"
        },
        {
            "key": "carraro",
            "value": "carraro",
            "label": "Carraro"
        },
        {
            "key": "chromag",
            "value": "chromag",
            "label": "Chromag"
        },
        {
            "key": "civia",
            "value": "civia",
            "label": "Civia"
        },
        {
            "key": "clean",
            "value": "clean",
            "label": "Clean"
        },
        {
            "key": "cleary",
            "value": "cleary",
            "label": "Cleary"
        },
        {
            "key": "comas",
            "value": "comas",
            "label": "Comas"
        },
        {
            "key": "commercal",
            "value": "commercal",
            "label": "Commercal"
        },
        {
            "key": "contura",
            "value": "contura",
            "label": "Contura"
        },
        {
            "key": "conway",
            "value": "conway",
            "label": "Conway"
        },
        {
            "key": "corratec",
            "value": "corratec",
            "label": "Corratec"
        },
        {
            "key": "czar",
            "value": "czar",
            "label": "Czar"
        },
        {
            "key": "daccordi",
            "value": "daccordi",
            "label": "Daccordi"
        },
        {
            "key": "dahon",
            "value": "dahon",
            "label": "Dahon"
        },
        {
            "key": "dartmoor",
            "value": "dartmoor",
            "label": "Dartmoor"
        },
        {
            "key": "de_rosa",
            "value": "de_rosa",
            "label": "De Rosa"
        },
        {
            "key": "deviate",
            "value": "deviate",
            "label": "Deviate"
        },
        {
            "key": "diamant",
            "value": "diamant",
            "label": "Diamant"
        },
        {
            "key": "diamondback",
            "value": "diamondback",
            "label": "Diamondback"
        },
        {
            "key": "e.bikemanufaktur",
            "value": "e.bikemanufaktur",
            "label": "e.bikemanufaktur"
        },
        {
            "key": "early_rider",
            "value": "early_rider",
            "label": "Early Rider"
        },
        {
            "key": "eastern_bikes",
            "value": "eastern_bikes",
            "label": "Eastern Bikes"
        },
        {
            "key": "electra",
            "value": "electra",
            "label": "Electra"
        },
        {
            "key": "enigma",
            "value": "enigma",
            "label": "Enigma"
        },
        {
            "key": "gasgas",
            "value": "gasgas",
            "label": "GasGas"
        },
        {
            "key": "gazelle",
            "value": "gazelle",
            "label": "Gazelle"
        },
        {
            "key": "gepida",
            "value": "gepida",
            "label": "Gepida"
        },
        {
            "key": "garmanxia",
            "value": "garmanxia",
            "label": "GarmanXia"
        },
        {
            "key": "grace",
            "value": "grace",
            "label": "Grace"
        },
        {
            "key": "guerrilla_gravity",
            "value": "guerrilla_gravity",
            "label": "Guerrilla Gravity"
        },
        {
        "key": "gekko",
        "value": "gekko",
        "label": "Gekko",
        },
        {
            "key": "haibike",
            "value": "haibike",
            "label": "Haibike"
        },
        {
            "key": "harthe_manufaktur",
            "value": "harthe_manufaktur",
            "label": "Harthe Manufaktur"
        },
        {
            "key": "head",
            "value": "head",
            "label": "Head"
        },
        {
            "key": "hnf_nicolai",
            "value": "hnf_nicolai",
            "label": "HNF-Nicolai"
        },
        {
            "key": "hoffman_bikes",
            "value": "hoffman_bikes",
            "label": "Hoffman Bikes"
        },
        {
            "key": "husqvarna",
            "value": "husqvarna",
            "label": "Husqvarna"
        },
        {
            "key": "ideal",
            "value": "ideal",
            "label": "Ideal"
        },
        {
            "key": "juliana",
            "value": "juliana",
            "label": "Juliana"
        },
        {
            "key": "kebra",
            "value": "kebra",
            "label": "Kebra"
        },
        {
            "key": "kalkhoff",
            "value": "kalkhoff",
            "label": "Kalkhoff"
        },
        {
            "key": "kavenz",
            "value": "kavenz",
            "label": "Kavenz"
        },
        {
            "key": "kayza",
            "value": "kayza",
            "label": "Kayza"
        },
        {
            "key": "kestrel_usa",
            "value": "kestrel_usa",
            "label": "Kestrel USA"
        },
        {
            "key": "koga",
            "value": "koga",
            "label": "Koga"
        },
        {
            "key": "lapierre",
            "value": "lapierre",
            "label": "Lapierre"
        },
        {
            "key": "last",
            "value": "last",
            "label": "Last"
        },
        {
            "key": "lee_cougan",
            "value": "lee_cougan",
            "label": "Lee Cougan"
        },
        {
            "key": "louis_garneau",
            "value": "louis_garneau",
            "label": "Louis Garneau"
        },
        {
            "key": "lynskey_performance",
            "value": "lynskey_performance",
            "label": "Lynskey Performance"
        },
        {
            "key": "maestro",
            "value": "maestro",
            "label": "Maestro"
        },
        {
            "key": "mafia_bikes",
            "value": "mafia_bikes",
            "label": "Mafia Bikes"
        },
        {
            "key": "manhattan",
            "value": "manhattan",
            "label": "Manhattan"
        },
        {
            "key": "magemo",
            "value": "magemo",
            "label": "Magemo"
        },
        {
            "key": "merida",
            "value": "merida",
            "label": "Merida"
        },
        {
            "key": "mirraco",
            "value": "mirraco",
            "label": "Mirraco"
        },
        {
            "key": "mondraker",
            "value": "mondraker",
            "label": "Mondraker"
        },
        {
            "key": "moustache",
            "value": "moustache",
            "label": "Moustache"
        },
        {
            "key": "octane_one",
            "value": "octane_one",
            "label": "Octane One"
        },
        {
            "key": "olympia",
            "value": "olympia",
            "label": "Olympia"
        },
        {
            "key": "orange",
            "value": "orange",
            "label": "Orange"
        },
        {
            "key": "patrol",
            "value": "patrol",
            "label": "Patrol"
        },
        {
            "key": "pegasus",
            "value": "pegasus",
            "label": "Pegasus"
        },
        {
            "key": "pinarello",
            "value": "pinarello",
            "label": "Pinarello"
        },
        {
            "key": "privateer",
            "value": "privateer",
            "label": "Privateer"
        },
        {
            "key": "propain",
            "value": "propain",
            "label": "Propain"
        },
        {
            "key": "pyga",
            "value": "pyga",
            "label": "Pyga"
        },
        {
            "key": "quietkat",
            "value": "quietkat",
            "label": "QuietKat"
        },
        {
            "key": "raaw",
            "value": "raaw",
            "label": "RAAW"
        },
        {
            "key": "rabeneick",
            "value": "rabeneick",
            "label": "Rabeneick"
        },
        {
            "key": "radio",
            "value": "radio",
            "label": "Radio"
        },
        {
            "key": "radon",
            "value": "radon",
            "label": "Radon"
        },
        {
            "key": "ragley",
            "value": "ragley",
            "label": "Ragley"
        },
        {
            "key": "raleigh_electric",
            "value": "raleigh_electric",
            "label": "Raleigh Electric"
        },
        {
            "key": "ramlon",
            "value": "ramlon",
            "label": "Ramlon"
        },
        {
            "key": "raymon",
            "value": "raymon",
            "label": "Raymon"
        },
        {
            "key": "resonator",
            "value": "resonator",
            "label": "Resonator"
        },
        {
            "key": "rock_machine",
            "value": "rock_machine",
            "label": "Rock Machine"
        },
        {
            "key": "rockman",
            "value": "rockman",
            "label": "Rockman"
        },
        {
            "key": "saracen",
            "value": "saracen",
            "label": "Saracen"
        },
        {
            "key": "saxonette",
            "value": "saxonette",
            "label": "Saxonette"
        },
        {
            "key": "shindelhauer",
            "value": "shindelhauer",
            "label": "Shindelhauer"
        },
        {
            "key": "se_racing",
            "value": "se_racing",
            "label": "SE Racing"
        },
        {
            "key": "ns_bikes",
            "value": "ns_bikes",
            "label": "NS Bikes"
        },
        {
            "key": "stevens_bikes",
            "value": "stevens_bikes",
            "label": "Stevens Bikes"
        },
        {
            "key": "ghost",
            "value": "ghost",
            "label": "Ghost"
        },
        {
            "key": "no_brand",
            "value": "no_brand",
            "label": "No brand"
        },
        {
            "key": "ghos",
            "value": "ghos",
            "label": "Ghos"
        },
        {
            "key": "2_danger",
            "value": "2_danger",
            "label": "2 Danger"
        },
        {
            "key": "7mesh",
            "value": "7mesh",
            "label": "7Mesh"
        },
        {
            "key": "aegis",
            "value": "aegis",
            "label": "Aegis"
        },
        {
            "key": "aero_tech_designs",
            "value": "aero_tech_designs",
            "label": "Aero Tech Designs"
        },
        {
            "key": "aest",
            "value": "aest",
            "label": "Aest"
        },
        {
            "key": "allied",
            "value": "allied",
            "label": "Allied"
        },
        {
            "key": "alpinestars",
            "value": "alpinestars",
            "label": "Alpinestars"
        },
        {
            "key": "answer",
            "value": "answer",
            "label": "Answer"
        },
        {
            "key": "aquila",
            "value": "aquila",
            "label": "Aquila"
        },
        {
            "key": "argonaut",
            "value": "argonaut",
            "label": "Argonaut"
        },
        {
            "key": "ari",
            "value": "ari",
            "label": "Ari"
        },
        {
            "key": "atala",
            "value": "atala",
            "label": "Atala"
        },
        {
            "key": "atherton",
            "value": "atherton",
            "label": "Atherton"
        },
        {
            "key": "azonic",
            "value": "azonic",
            "label": "AZONIC"
        },
        {
            "key": "barracuda",
            "value": "barracuda",
            "label": "BARRACUDA"
        },
        {
            "key": "basis",
            "value": "basis",
            "label": "BASIS"
        },
        {
            "key": "bearclaw",
            "value": "bearclaw",
            "label": "Bearclaw"
        },
        {
            "key": "bell",
            "value": "bell",
            "label": "Bell"
        },
        {
            "key": "bellwether",
            "value": "bellwether",
            "label": "Bellwether"
        },
        {
            "key": "benno",
            "value": "benno",
            "label": "Benno"
        },
        {
            "key": "benotti",
            "value": "benotti",
            "label": "Benotti"
        },
        {
            "key": "bh_bikes",
            "value": "bh_bikes",
            "label": "BH Bikes"
        },
        {
            "key": "biktrix",
            "value": "biktrix",
            "label": "Biktrix"
        },
        {
            "key": "bird",
            "value": "bird",
            "label": "Bird"
        },
        {
            "key": "bixs",
            "value": "bixs",
            "label": "BiXS"
        },
        {
            "key": "blix",
            "value": "blix",
            "label": "Blix"
        },
        {
            "key": "boardman",
            "value": "boardman",
            "label": "Boardman"
        },
        {
            "key": "bold",
            "value": "bold",
            "label": "Bold"
        },
        {
            "key": "borealis",
            "value": "borealis",
            "label": "Borealis"
        },
        {
            "key": "breezer",
            "value": "breezer",
            "label": "Breezer"
        },
        {
            "key": "bridge_bikeworks",
            "value": "bridge_bikeworks",
            "label": "Bridge Bikeworks"
        },
        {
            "key": "briko",
            "value": "briko",
            "label": "Briko"
        },
        {
            "key": "brodie",
            "value": "brodie",
            "label": "Brodie"
        },
        {
            "key": "bulls",
            "value": "bulls",
            "label": "BULLS"
        },
        {
            "key": "cairn",
            "value": "cairn",
            "label": "Cairn"
        },
        {
            "key": "calfee",
            "value": "calfee",
            "label": "Calfee"
        },
        {
            "key": "canari",
            "value": "canari",
            "label": "Canari"
        },
        {
            "key": "casco",
            "value": "casco",
            "label": "Casco"
        },
        {
            "key": "castelli",
            "value": "castelli",
            "label": "Castelli"
        },
        {
            "key": "cateye",
            "value": "cateye",
            "label": "CatEye"
        },
        {
            "key": "centurion",
            "value": "centurion",
            "label": "Centurion"
        },
        {
            "key": "chapter_2",
            "value": "chapter_2",
            "label": "Chapter 2"
        },
        {
            "key": "charge",
            "value": "charge",
            "label": "Charge"
        },
        {
            "key": "checker_pig",
            "value": "checker_pig",
            "label": "CHECKER PIG"
        },
        {
            "key": "cilo_swiss",
            "value": "cilo_swiss",
            "label": "Cilo Swiss"
        },
        {
            "key": "cinelli",
            "value": "cinelli",
            "label": "Cinelli"
        },
        {
            "key": "ciöcc",
            "value": "ciöcc",
            "label": "CIÖCC"
        },
        {
            "key": "cipollini",
            "value": "cipollini",
            "label": "Cipollini"
        },
        {
            "key": "clarks",
            "value": "clarks",
            "label": "Clarks"
        },
        {
            "key": "claud_butler",
            "value": "claud_butler",
            "label": "Claud Butler"
        },
        {
            "key": "climb_high",
            "value": "climb_high",
            "label": "Climb High"
        },
        {
            "key": "club_ride",
            "value": "club_ride",
            "label": "Club Ride"
        },
        {
            "key": "co_op_cycles",
            "value": "co_op_cycles",
            "label": "Co-Op Cycles"
        },
        {
            "key": "coboc",
            "value": "coboc",
            "label": "Coboc"
        },
        {
            "key": "colner",
            "value": "colner",
            "label": "Colner"
        },
        {
            "key": "commuter",
            "value": "commuter",
            "label": "Commuter"
        },
        {
            "key": "control_tech",
            "value": "control_tech",
            "label": "Control Tech"
        },
        {
            "key": "corima",
            "value": "corima",
            "label": "Corima"
        },
        {
            "key": "cowboy",
            "value": "cowboy",
            "label": "Cowboy"
        },
        {
            "key": "creme",
            "value": "creme",
            "label": "Creme"
        },
        {
            "key": "crisp",
            "value": "crisp",
            "label": "Crisp"
        },
        {
            "key": "critical_cycles",
            "value": "critical_cycles",
            "label": "Critical Cycles"
        },
        {
            "key": "ctm",
            "value": "ctm",
            "label": "CTM"
        },
        {
            "key": "cucuma",
            "value": "cucuma",
            "label": "Cucuma"
        },
        {
            "key": "curve",
            "value": "curve",
            "label": "Curve"
        },
        {
            "key": "dancelli",
            "value": "dancelli",
            "label": "Dancelli"
        },
        {
            "key": "dassi",
            "value": "dassi",
            "label": "Dassi"
        },
        {
            "key": "decathlon",
            "value": "decathlon",
            "label": "Decathlon"
        },
        {
            "key": "dedacciai",
            "value": "dedacciai",
            "label": "Dedacciai"
        },
        {
            "key": "del_sol",
            "value": "del_sol",
            "label": "Del Sol"
        },
        {
            "key": "denago",
            "value": "denago",
            "label": "Denago"
        },
        {
            "key": "dōst",
            "value": "dōst",
            "label": "Dōst"
        },
        {
            "key": "dt_swiss",
            "value": "dt_swiss",
            "label": "DT Swiss"
        },
        {
            "key": "e_bikemanufaktur",
            "value": "e_bikemanufaktur",
            "label": "E-bikemanufaktur"
        },
        {
            "key": "evil",
            "value": "evil",
            "label": "Evil"
        },
        {
            "key": "exustar",
            "value": "exustar",
            "label": "Exustar"
        },
        {
            "key": "fizik",
            "value": "fizik",
            "label": "Fizik"
        },
        {
            "key": "fsa",
            "value": "fsa",
            "label": "FSA"
        },
        {
            "key": "fulcrum",
            "value": "fulcrum",
            "label": "Fulcrum"
        },
        {
            "key": "gitane",
            "value": "gitane",
            "label": "Gitane"
        },
        {
            "key": "gocycle",
            "value": "gocycle",
            "label": "Gocycle"
        },
        {
            "key": "goomah",
            "value": "goomah",
            "label": "Goomah"
        },
        {
            "key": "granville",
            "value": "granville",
            "label": "Granville"
        },
        {
            "key": "greenline",
            "value": "greenline",
            "label": "GreenLine"
        },
        {
            "key": "greg_lemond",
            "value": "greg_lemond",
            "label": "Greg LeMond"
        },
        {
            "key": "grenade",
            "value": "grenade",
            "label": "Grenade"
        },
        {
            "key": "guru",
            "value": "guru",
            "label": "Guru"
        },
        {
            "key": "handmade",
            "value": "handmade",
            "label": "Handmade"
        },
        {
            "key": "heller_shagamaw",
            "value": "heller_shagamaw",
            "label": "Heller Shagamaw"
        },
        {
            "key": "heller",
            "value": "heller",
            "label": "Heller"
        },
        {
            "key": "helkama",
            "value": "helkama",
            "label": "Helkama"
        },
        {
            "key": "hercules",
            "value": "hercules",
            "label": "Hercules"
        },
        {
            "key": "hifi",
            "value": "hifi",
            "label": "HiFi"
        },
        {
            "key": "holdsworth",
            "value": "holdsworth",
            "label": "Holdsworth"
        },
        {
            "key": "huffy",
            "value": "huffy",
            "label": "Huffy"
        },
        {
            "key": "hylix",
            "value": "hylix",
            "label": "Hylix"
        },
        {
            "key": "if_mode",
            "value": "if_mode",
            "label": "IF Mode"
        },
        {
            "key": "intense",
            "value": "intense",
            "label": "Intense"
        },
        {
            "key": "itm",
            "value": "itm",
            "label": "ITM"
        },
        {
            "key": "javelin",
            "value": "javelin",
            "label": "Javelin"
        },
        {
            "key": "jeep",
            "value": "jeep",
            "label": "Jeep"
        },
        {
            "key": "kenda",
            "value": "kenda",
            "label": "Kenda"
        },
        {
            "key": "kestrel",
            "value": "kestrel",
            "label": "Kestrel"
        },
        {
            "key": "kuota",
            "value": "kuota",
            "label": "Kuota"
        },
        {
            "key": "kymco",
            "value": "kymco",
            "label": "Kymco"
        },
        {
            "key": "lauf",
            "value": "lauf",
            "label": "Lauf"
        },
        {
            "key": "lazer",
            "value": "lazer",
            "label": "Lazer"
        },
        {
            "key": "lemond",
            "value": "lemond",
            "label": "LeMond"
        },
        {
            "key": "litespeed",
            "value": "litespeed",
            "label": "Litespeed"
        },
        {
            "key": "malvern_star",
            "value": "malvern_star",
            "label": "Malvern Star"
        },
        {
            "key": "masi",
            "value": "masi",
            "label": "Masi"
        },
        {
            "key": "massi",
            "value": "massi",
            "label": "Massi"
        },
        {
            "key": "mavic",
            "value": "mavic",
            "label": "Mavic"
        },
        {
            "key": "mbk",
            "value": "mbk",
            "label": "MBK"
        },
        {
            "key": "merckx",
            "value": "merckx",
            "label": "Merckx"
        },
        {
            "key": "miele",
            "value": "miele",
            "label": "Miele"
        },
        {
            "key": "mikkelsen",
            "value": "mikkelsen",
            "label": "Mikkelsen"
        },
        {
            "key": "montague",
            "value": "montague",
            "label": "Montague"
        },
        {
            "key": "monty",
            "value": "monty",
            "label": "Monty"
        },
        {
            "key": "moots",
            "value": "moots",
            "label": "Moots"
        },
        {
            "key": "msc_bikes",
            "value": "msc_bikes",
            "label": "MSC Bikes"
        },
        {
            "key": "muddy_fox",
            "value": "muddy_fox",
            "label": "Muddy Fox"
        },
        {
            "key": "origin8",
            "value": "origin8",
            "label": "Origin8"
        },
        {
            "key": "orro",
            "value": "orro",
            "label": "Orro"
        },
        {
            "key": "oyama",
            "value": "oyama",
            "label": "Oyama"
        },
        {
            "key": "pace",
            "value": "pace",
            "label": "Pace"
        },
        {
            "key": "pacific",
            "value": "pacific",
            "label": "Pacific"
        },
        {
            "key": "pashley",
            "value": "pashley",
            "label": "Pashley"
        },
        {
            "key": "patagonia",
            "value": "patagonia",
            "label": "Patagonia"
        },
        {
            "key": "pegoretti",
            "value": "pegoretti",
            "label": "Pegoretti"
        },
        {
            "key": "pello",
            "value": "pello",
            "label": "Pello"
        },
        {
            "key": "",
            "value": "",
            "label": ""
        },
        {
            "key": "profile_design",
            "value": "profile_design",
            "label": "Profile Design"
        },
        {
            "key": "primo",
            "value": "primo",
            "label": "Primo"
        },
        {
            "key": "power_grips",
            "value": "power_grips",
            "label": "Power Grips"
        },
        {
            "key": "polaris",
            "value": "polaris",
            "label": "Polaris"
        },
        {
            "key": "planet_bike",
            "value": "planet_bike",
            "label": "Planet Bike"
        },
        {
            "key": "pedro's",
            "value": "pedro's",
            "label": "Pedro's"
        },
        {
            "key": "park_tool",
            "value": "park_tool",
            "label": "Park Tool"
        },
        {
            "key": "pacline_products",
            "value": "pacline_products",
            "label": "Pacline Products"
        },
        {
            "key": "pacenti",
            "value": "pacenti",
            "label": "Pacenti"
        },
        {
            "key": "pryme",
            "value": "pryme",
            "label": "PRYME"
        },
        {
            "key": "promax",
            "value": "promax",
            "label": "PROMAX"
        },
        {
            "key": "poc",
            "value": "poc",
            "label": "POC"
        },
        {
            "key": "outdoor_research",
            "value": "outdoor_research",
            "label": "Outdoor Research"
        },
        {
            "key": "osprey",
            "value": "osprey",
            "label": "Osprey"
        },
        {
            "key": "orion_packs",
            "value": "orion_packs",
            "label": "Orion Packs"
        },
        {
            "key": "wtb",
            "value": "wtb",
            "label": "WTB"
        },
        {
            "key": "ryders",
            "value": "ryders",
            "label": "Ryders"
        },
        {
            "key": "rudy_project",
            "value": "rudy_project",
            "label": "Rudy Project"
        },
        {
            "key": "rohloff",
            "value": "rohloff",
            "label": "Rohloff"
        },
        {
            "key": "rocky_mountain_underground",
            "value": "rocky_mountain_underground",
            "label": "Rocky Mountain Underground"
        },
        {
            "key": "rockshox",
            "value": "rockshox",
            "label": "Rockshox"
        },
        {
            "key": "rockbros",
            "value": "rockbros",
            "label": "RockBros"
        },
        {
            "key": "ritchey",
            "value": "ritchey",
            "label": "Ritchey"
        },
        {
            "key": "reynolds",
            "value": "reynolds",
            "label": "Reynolds"
        },
        {
            "key": "renthal",
            "value": "renthal",
            "label": "Renthal"
        },
        {
            "key": "rayzor",
            "value": "rayzor",
            "label": "Rayzor"
        },
        {
            "key": "race_face",
            "value": "race_face",
            "label": "Race Face"
        },
        {
            "key": "rivbos",
            "value": "rivbos",
            "label": "RIVBOS"
        },
        {
            "key": "rdx",
            "value": "rdx",
            "label": "RDX"
        },
        {
            "key": "quarq",
            "value": "quarq",
            "label": "Quarq"
        },
        {
            "key": "propalm",
            "value": "propalm",
            "label": "Propalm"
        },
        {
            "key": "muc_off",
            "value": "muc_off",
            "label": "Muc-Off"
        },
        {
            "key": "modolo",
            "value": "modolo",
            "label": "Modolo"
        },
        {
            "key": "mission",
            "value": "mission",
            "label": "Mission"
        },
        {
            "key": "metis",
            "value": "metis",
            "label": "Metis"
        },
        {
            "key": "mechanix_wear",
            "value": "mechanix_wear",
            "label": "Mechanix Wear"
        },
        {
            "key": "marzocchi",
            "value": "marzocchi",
            "label": "Marzocchi"
        },
        {
            "key": "marker",
            "value": "marker",
            "label": "Marker"
        },
        {
            "key": "malini",
            "value": "malini",
            "label": "Malini"
        },
        {
            "key": "mrp",
            "value": "mrp",
            "label": "MRP"
        },
        {
            "key": "mowa",
            "value": "mowa",
            "label": "MOWA"
        },
        {
            "key": "mks",
            "value": "mks",
            "label": "MKS"
        },
        {
            "key": "lyotard",
            "value": "lyotard",
            "label": "Lyotard"
        },
        {
            "key": "orca",
            "value": "orca",
            "label": "Orca"
        },
        {
            "key": "optic_nerve",
            "value": "optic_nerve",
            "label": "Optic Nerve"
        },
        {
            "key": "olmo",
            "value": "olmo",
            "label": "Olmo"
        },
        {
            "key": "ohlins",
            "value": "ohlins",
            "label": "Ohlins"
        },
        {
            "key": "odyssey",
            "value": "odyssey",
            "label": "Odyssey"
        },
        {
            "key": "oakley",
            "value": "oakley",
            "label": "Oakley"
        },
        {
            "key": "odi",
            "value": "odi",
            "label": "ODI"
        },
        {
            "key": "o2",
            "value": "o2",
            "label": "O2"
        },
        {
            "key": "o'neal",
            "value": "o'neal",
            "label": "O'Neal"
        },
        {
            "key": "nox",
            "value": "nox",
            "label": "Nox"
        },
        {
            "key": "novatec",
            "value": "novatec",
            "label": "Novatec"
        },
        {
            "key": "northwave",
            "value": "northwave",
            "label": "Northwave"
        },
        {
            "key": "norrona",
            "value": "norrona",
            "label": "Norrona"
        },
        {
            "key": "nitto",
            "value": "nitto",
            "label": "Nitto"
        },
        {
            "key": "new_balance",
            "value": "new_balance",
            "label": "New Balance"
        },
        {
            "key": "nathan",
            "value": "nathan",
            "label": "Nathan"
        },
        {
            "key": "vuelta",
            "value": "vuelta",
            "label": "Vuelta"
        },
        {
            "key": "velocity",
            "value": "velocity",
            "label": "Velocity"
        },
        {
            "key": "velo_orange",
            "value": "velo_orange",
            "label": "Velo Orange"
        },
        {
            "key": "vp",
            "value": "vp",
            "label": "VP"
        },
        {
            "key": "unbranded",
            "value": "unbranded",
            "label": "Unbranded"
        },
        {
            "key": "ultimate",
            "value": "ultimate",
            "label": "Ultimate"
        },
        {
            "key": "uvex",
            "value": "uvex",
            "label": "UVEX"
        },
        {
            "key": "troy_lee_designs",
            "value": "troy_lee_designs",
            "label": "Troy Lee Designs"
        },
        {
            "key": "transition",
            "value": "transition",
            "label": "Transition"
        },
        {
            "key": "topeak_sports",
            "value": "topeak_sports",
            "label": "Topeak Sports"
        },
        {
            "key": "tifosi",
            "value": "tifosi",
            "label": "Tifosi"
        },
        {
            "key": "thomson",
            "value": "thomson",
            "label": "Thomson"
        },
        {
            "key": "the_shadow_conspiracy",
            "value": "the_shadow_conspiracy",
            "label": "The Shadow Conspiracy"
        },
        {
            "key": "tangent",
            "value": "tangent",
            "label": "Tangent"
        },
        {
            "key": "truvativ",
            "value": "truvativ",
            "label": "TRUVATIV"
        },
        {
            "key": "toseek",
            "value": "toseek",
            "label": "TOSEEK"
        },
        {
            "key": "tioga",
            "value": "tioga",
            "label": "TIOGA"
        },
        {
            "key": "time",
            "value": "time",
            "label": "TIME"
        },
        {
            "key": "crankbrothers",
            "value": "crankbrothers",
            "label": "crankbrothers"
        },
        {
            "key": "zipp",
            "value": "zipp",
            "label": "Zipp"
        },
        {
            "key": "zimco",
            "value": "zimco",
            "label": "Zimco"
        },
        {
            "key": "yokozuna",
            "value": "yokozuna",
            "label": "Yokozuna"
        },
        {
            "key": "yt",
            "value": "yt",
            "label": "YT"
        },
        {
            "key": "xpedo",
            "value": "xpedo",
            "label": "Xpedo"
        },
        {
            "key": "xloop",
            "value": "xloop",
            "label": "Xloop"
        },
        {
            "key": "xentis",
            "value": "xentis",
            "label": "Xentis"
        },
        {
            "key": "xact",
            "value": "xact",
            "label": "Xact"
        },
        {
            "key": "xlc",
            "value": "xlc",
            "label": "XLC"
        },
        {
            "key": "wolftooth",
            "value": "wolftooth",
            "label": "Wolftooth"
        },
        {
            "key": "white_lightning",
            "value": "white_lightning",
            "label": "White Lightning"
        },
        {
            "key": "wheelsmith",
            "value": "wheelsmith",
            "label": "Wheelsmith"
        },
        {
            "key": "wheels_manufacturing",
            "value": "wheels_manufacturing",
            "label": "Wheels Manufacturing"
        },
        {
            "key": "wellgo",
            "value": "wellgo",
            "label": "Wellgo"
        },
        {
            "key": "weinmann",
            "value": "weinmann",
            "label": "Weinmann"
        },
        {
            "key": "walleva",
            "value": "walleva",
            "label": "Walleva"
        },
        {
            "key": "wald",
            "value": "wald",
            "label": "Wald"
        },
        {
            "key": "wahoo_fitness",
            "value": "wahoo_fitness",
            "label": "Wahoo Fitness"
        },
        {
            "key": "sixsixone",
            "value": "sixsixone",
            "label": "SixSixOne"
        },
        {
            "key": "sigma_sport",
            "value": "sigma_sport",
            "label": "Sigma Sport"
        },
        {
            "key": "sidi",
            "value": "sidi",
            "label": "Sidi"
        },
        {
            "key": "showers_pass",
            "value": "showers_pass",
            "label": "Showers Pass"
        },
        {
            "key": "sheebeest",
            "value": "sheebeest",
            "label": "Sheebeest"
        },
        {
            "key": "serfas",
            "value": "serfas",
            "label": "Serfas"
        },
        {
            "key": "sealskinz",
            "value": "sealskinz",
            "label": "SealSkinz"
        },
        {
            "key": "santini",
            "value": "santini",
            "label": "Santini"
        },
        {
            "key": "supacaz",
            "value": "supacaz",
            "label": "SUPACAZ"
        },
        {
            "key": "sugoi",
            "value": "sugoi",
            "label": "SUGOI"
        },
        {
            "key": "sram",
            "value": "sram",
            "label": "SRAM"
        },
        {
            "key": "sr",
            "value": "sr",
            "label": "SR"
        },
        {
            "key": "spank",
            "value": "spank",
            "label": "SPANK"
        },
        {
            "key": "salice",
            "value": "salice",
            "label": "SALICE"
        },
        {
            "key": "s&m",
            "value": "s&m",
            "label": "S&M"
        },
        {
            "key": "tektro",
            "value": "tektro",
            "label": "TEKTRO"
        },
        {
            "key": "syntace",
            "value": "syntace",
            "label": "Syntace"
        },
        {
            "key": "swissstop",
            "value": "swissstop",
            "label": "SwissStop"
        },
        {
            "key": "swift",
            "value": "swift",
            "label": "Swift"
        },
        {
            "key": "sweet_protection",
            "value": "sweet_protection",
            "label": "Sweet Protection"
        },
        {
            "key": "suntour",
            "value": "suntour",
            "label": "Suntour"
        },
        {
            "key": "sunlite",
            "value": "sunlite",
            "label": "Sunlite"
        },
        {
            "key": "sun_ringle",
            "value": "sun_ringle",
            "label": "Sun Ringle"
        },
        {
            "key": "sturmey_archer",
            "value": "sturmey_archer",
            "label": "Sturmey-Archer"
        },
        {
            "key": "stradalli_cycle",
            "value": "stradalli_cycle",
            "label": "Stradalli Cycle"
        },
        {
            "key": "storck",
            "value": "storck",
            "label": "Storck"
        },
        {
            "key": "stevens",
            "value": "stevens",
            "label": "Stevens"
        },
        {
            "key": "stages",
            "value": "stages",
            "label": "Stages"
        },
        {
            "key": "sportful",
            "value": "sportful",
            "label": "Sportful"
        },
        {
            "key": "speedplay",
            "value": "speedplay",
            "label": "Speedplay"
        },
        {
            "key": "spakct",
            "value": "spakct",
            "label": "Spakct"
        },
        {
            "key": "soma",
            "value": "soma",
            "label": "Soma"
        },
        {
            "key": "sobike",
            "value": "sobike",
            "label": "Sobike"
        },
        {
            "key": "smartwool",
            "value": "smartwool",
            "label": "SmartWool"
        },
        {
            "key": "skins",
            "value": "skins",
            "label": "Skins"
        },
        {
            "key": "columbia",
            "value": "columbia",
            "label": "Columbia"
        },
        {
            "key": "climax",
            "value": "climax",
            "label": "Climax"
        },
        {
            "key": "christophe",
            "value": "christophe",
            "label": "Christophe"
        },
        {
            "key": "chris_king",
            "value": "chris_king",
            "label": "Chris King"
        },
        {
            "key": "chaco",
            "value": "chaco",
            "label": "Chaco"
        },
        {
            "key": "ceepo",
            "value": "ceepo",
            "label": "Ceepo"
        },
        {
            "key": "catlike",
            "value": "catlike",
            "label": "Catlike"
        },
        {
            "key": "capo",
            "value": "capo",
            "label": "Capo"
        },
        {
            "key": "esi",
            "value": "esi",
            "label": "ESI"
        },
        {
            "key": "enve",
            "value": "enve",
            "label": "ENVE"
        },
        {
            "key": "dumonde_tech",
            "value": "dumonde_tech",
            "label": "Dumonde Tech"
        },
        {
            "key": "dragon",
            "value": "dragon",
            "label": "Dragon"
        },
        {
            "key": "divo",
            "value": "divo",
            "label": "Divo"
        },
        {
            "key": "diadora",
            "value": "diadora",
            "label": "Diadora"
        },
        {
            "key": "deuter",
            "value": "deuter",
            "label": "Deuter"
        },
        {
            "key": "delta_cycle",
            "value": "delta_cycle",
            "label": "Delta Cycle"
        },
        {
            "key": "deda_elementi",
            "value": "deda_elementi",
            "label": "Deda Elementi"
        },
        {
            "key": "defeet",
            "value": "defeet",
            "label": "DeFeet"
        },
        {
            "key": "darn_tough",
            "value": "darn_tough",
            "label": "Darn Tough"
        },
        {
            "key": "dakine",
            "value": "dakine",
            "label": "Dakine"
        },
        {
            "key": "dmr",
            "value": "dmr",
            "label": "DMR"
        },
        {
            "key": "dia_ompe",
            "value": "dia_compe",
            "label": "DIA-COMPE"
        },
        {
            "key": "bbb",
            "value": "bbb",
            "label": "BBB"
        },
        {
            "key": "astral",
            "value": "astral",
            "label": "Astral"
        },
        {
            "key": "ashima",
            "value": "ashima",
            "label": "Ashima"
        },
        {
            "key": "arc'teryx",
            "value": "arc'teryx",
            "label": "Arc'teryx"
        },
        {
            "key": "american_classic",
            "value": "american_classic",
            "label": "American Classic"
        },
        {
            "key": "airius",
            "value": "airius",
            "label": "Airius"
        },
        {
            "key": "adidas",
            "value": "adidas",
            "label": "Adidas"
        },
        {
            "key": "acs",
            "value": "acs",
            "label": "ACS"
        },
        {
            "key": "abus",
            "value": "abus",
            "label": "ABUS"
        },
        {
            "key": "45nrth",
            "value": "45nrth",
            "label": "45NRTH"
        },
        {
            "key": "3t",
            "value": "3t",
            "label": "3T"
        },
        {
            "key": "cane_creek",
            "value": "cane_creek",
            "label": "Cane Creek"
        },
        {
            "key": "craft",
            "value": "craft",
            "label": "CRAFT"
        },
        {
            "key": "cep",
            "value": "cep",
            "label": "CEP"
        },
        {
            "key": "burton",
            "value": "burton",
            "label": "Burton"
        },
        {
            "key": "bridgedale",
            "value": "bridgedale",
            "label": "Bridgedale"
        },
        {
            "key": "boz_components",
            "value": "boz_components",
            "label": "Boz components"
        },
        {
            "key": "bontrager",
            "value": "bontrager",
            "label": "Bontrager"
        },
        {
            "key": "bern",
            "value": "bern",
            "label": "Bern"
        },
        {
            "key": "benotto",
            "value": "benotto",
            "label": "Benotto"
        },
        {
            "key": "bendix",
            "value": "bendix",
            "label": "Bendix"
        },
        {
            "key": "balega",
            "value": "balega",
            "label": "Balega"
        },
        {
            "key": "kali",
            "value": "kali",
            "label": "Kali"
        },
        {
            "key": "kore",
            "value": "kore",
            "label": "KORE"
        },
        {
            "key": "kcnc",
            "value": "kcnc",
            "label": "KCNC"
        },
        {
            "key": "k_edge",
            "value": "k_edge",
            "label": "K-edge"
        },
        {
            "key": "k&s",
            "value": "k&s",
            "label": "K&S"
        },
        {
        "key": "kross",
        "value": "kross",
        "label": "Kross"
        },
        {
            "key": "julbo",
            "value": "julbo",
            "label": "Julbo"
        },
        {
            "key": "jagwire",
            "value": "jagwire",
            "label": "Jagwire"
        },
        {
            "key": "j&l",
            "value": "j&l",
            "label": "J&L"
        },
        {
            "key": "industry_9",
            "value": "industry_9",
            "label": "Industry 9"
        },
        {
            "key": "ism",
            "value": "ism",
            "label": "ISM"
        },
        {
            "key": "hope",
            "value": "hope",
            "label": "Hope"
        },
        {
            "key": "look",
            "value": "look",
            "label": "Look"
        },
        {
            "key": "lizard_skins",
            "value": "lizard_skins",
            "label": "Lizard Skins"
        },
        {
            "key": "lezyne",
            "value": "lezyne",
            "label": "Lezyne"
        },
        {
            "key": "landshark",
            "value": "landshark",
            "label": "Landshark"
        },
        {
            "key": "lamere_cycles",
            "value": "lamere_cycles",
            "label": "Lamere Cycles"
        },
        {
            "key": "lixada",
            "value": "lixada",
            "label": "LIXADA"
        },
        {
            "key": "kool_stop",
            "value": "kool_stop",
            "label": "Kool-Stop"
        },
        {
            "key": "knight_composites",
            "value": "knight_composites",
            "label": "Knight Composites"
        },
        {
            "key": "kask",
            "value": "kask",
            "label": "Kask"
        },
        {
            "key": "kalloy",
            "value": "kalloy",
            "label": "Kalloy"
        },
        {
            "key": "fyxation",
            "value": "fyxation",
            "label": "Fyxation"
        },
        {
            "key": "free_agent",
            "value": "free_agent",
            "label": "Free Agent"
        },
        {
            "key": "fox",
            "value": "fox",
            "label": "Fox"
        },
        {
            "key": "fly_racing",
            "value": "fly_racing",
            "label": "Fly Racing"
        },
        {
            "key": "finish_line",
            "value": "finish_line",
            "label": "Finish Line"
        },
        {
            "key": "feedback_sports",
            "value": "feedback_sports",
            "label": "Feedback Sports"
        },
        {
            "key": "fairdale",
            "value": "fairdale",
            "label": "Fairdale"
        },
        {
            "key": "etxeondo",
            "value": "etxeondo",
            "label": "Etxeondo"
        },
        {
            "key": "endura",
            "value": "endura",
            "label": "Endura"
        },
        {
            "key": "easton",
            "value": "easton",
            "label": "Easton"
        },
        {
            "key": "eagle",
            "value": "eagle",
            "label": "Eagle"
        },
        {
            "key": "ht_enterprises",
            "value": "ht_enterprises",
            "label": "HT Enterprises"
        },
        {
            "key": "hjc",
            "value": "hjc",
            "label": "HJC"
        },
        {
            "key": "hed",
            "value": "hed",
            "label": "HED"
        },
        {
            "key": "guerilla_gravity",
            "value": "guerilla_gravity",
            "label": "Guerilla Gravity"
        },
        {
            "key": "glovestix",
            "value": "glovestix",
            "label": "GloveStix"
        },
        {
            "key": "giro",
            "value": "giro",
            "label": "Giro"
        },
        {
            "key": "gatorz",
            "value": "gatorz",
            "label": "Gatorz"
        },
        {
            "key": "garmin",
            "value": "garmin",
            "label": "Garmin"
        },
        {
            "key": "gub",
            "value": "gub",
            "label": "GUB"
        },
        {
            "key": "gore_bike_wear",
            "value": "gore_bike_wear",
            "label": "GORE BIKE WEAR"
        },
        {
            "key": "g_form",
            "value": "g_form",
            "label": "G-Form"
        },
        {
            "key": "vitus",
            "value": "vitus",
            "label": "Vitus"
        },
        {
            "key": "kellys",
            "value": "kellys",
            "label": "Kellys"
        },
  ]
  },
}
